<template>
  <v-container class="timesheet-container">
    <timesheet-tabs />

    <generate-timesheet :edit-timesheet="edit" />
  </v-container>
</template>

<script>
import formats from '@/mixins/formats';
import GenerateTimesheet from '@/components/timesheets/GenerateTimesheet.vue';
import TimesheetTabs from '@/components/tabs/TimesheetTabs.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'Timesheet',
  components: {
    GenerateTimesheet,
    TimesheetTabs,
  },
  mixins: [formats],
  data: () => ({
    context: 'timesheet',
  }),
  computed: {
    edit() {
      return !!this.$route.params.id;
    },
  },
  created() {
    if (!this.getComponentFatherDate || this.getComponentFatherDate !== this.context) {
      this.saveDateFatherComponent(this.context);
      this.saveDateRange(this.currentMonth());
    }
  },
  mounted() {
    this.initCurrentBreadcrumbs([
      {
        text: this.$i18n.t('breadcrumbs.close_sheet'),
        disabled: true,
      },
    ]);
  },
  methods: {
    ...mapActions([
      'initCurrentBreadcrumbs',
      'saveDateFatherComponent',
      'saveDateRange',
    ]),
    ...mapGetters([
      'getCurrentBreadcrumbs',
      'getDateRange',
      'getComponentFatherDate',
    ]),
  },
};
</script>

<style lang="sccs" src="@/assets/scss/components/timesheets/timesheet.scss" scoped />

<template>
  <v-container class="generate-timesheet-container">
    <generate-timesheet-header
      :params="getSpecificTimesheet"
      :branches="getBranches"
      :business-units="getBusinessUnits"
      :profiles="getDriversProfiles"
      :clear-dates="dates"
      :cancelling-timesheet="cancellingTimesheet"
      @fetchUsersByTerm="fetchUsersByTerm"
      @changeValuesEnterprise="changeValuesEnterprise"
      @draftTimesheet="draftTimesheet"
      @businessUnitSelected="businessUnitSelected"
      @timesheetWasGenerated="timesheetWasGenerated"
      @resetCancellingTimesheet="resetCancellingTimesheet"
    />

    <timesheet-mirror-list
      v-if="show"
      :mirrors="mirrors"
    />

    <v-row
      v-if="show"
      justify="end"
    >
      <pagination
        v-if="showPagination"
        :pagination-settings="draftPagination"
        :page="getPage"
        @changed="changePage"
      />
    </v-row>

    <v-row
      v-if="show"
      justify="end"
    >
      <div v-if="editTimesheet">
        <v-btn
          class="cancel-button spacing"
          width="156"
          height="42"
          @click="cancelEdit"
        >
          {{ text("cancel") }}
        </v-btn>
        <v-btn
          class="spacing"
          width="164"
          height="42"
          color="accent"
          @click="updateOneTimesheet('open')"
        >
          {{ text("update") }}
        </v-btn>
        <v-btn
          class="spacing"
          width="156"
          height="42"
          color="accent"
          @click="updateOneTimesheet('closed')"
        >
          {{ text("save_closed") }}
        </v-btn>
      </div>
      <div v-else>
        <v-btn
          class="cancel-button spacing"
          width="156"
          height="42"
          @click="cancel"
        >
          {{ text("cancel") }}
        </v-btn>
        <v-btn
          class="spacing"
          width="156"
          height="42"
          color="accent"
          @click="saveDraft('open')"
        >
          {{ text("save_open") }}
        </v-btn>
        <v-btn
          class="spacing"
          width="156"
          height="42"
          color="accent"
          @click="saveDraft('closed')"
        >
          {{ text("save_closed") }}
        </v-btn>
      </div>
    </v-row>

    <placeholder
      v-else
      :message="$t('components.lists.timesheets.empty')"
    />
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import Placeholder from '@/components/lists/Placeholder.vue';
import Pagination from '@/components/pagination/Pagination.vue';
import GenerateTimesheetHeader from '@/components/headers/GenerateTimesheetHeader.vue';
import TimesheetMirrorList from '@/components/lists/TimesheetMirrorList.vue';

export default {
  name: 'GenerateTimesheet',
  components: {
    Pagination,
    GenerateTimesheetHeader,
    TimesheetMirrorList,
    Placeholder,
  },
  props: {
    editTimesheet: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data: () => ({
    dates: null,
    show: false,
    params: {},
    branchId: null,
    unitId: null,
    showEditButton: false,
    cancellingTimesheet: false,
    page: 1,
  }),
  computed: {
    ...mapGetters([
      'getTimesheetDraft',
      'getSpecificTimesheet',
      'getBranches',
      'getBusinessUnits',
      'getDriversProfiles',
      'getPage',
    ]),
    draftPagination() {
      if (this.getTimesheetDraft.pagination_settings) {
        return this.getTimesheetDraft.pagination_settings;
      }
      return {};
    },
    mirrors() {
      if (this.editTimesheet && !this.show) {
        return this.getSpecificTimesheet.mirrors;
      }
      if (this.editTimesheet && this.show) {
        return this.getTimesheetDraft.data;
      }
      return this.getTimesheetDraft.data;
    },
    showPagination() {
      if (this.getTimesheetDraft.data.length !== 0) {
        return true;
      }
      return false;
    },
  },
  watch: {
    getTimesheetDraft(value) {
      if (value && value.data.length !== 0) {
        this.show = true;
      } else {
        this.show = false;
      }
    },
  },
  mounted() {
    this.fetchDriverProfiles();
    this.fetchBusinessUnits({ by_active: true });
  },
  methods: {
    resetCancellingTimesheet() {
      this.cancellingTimesheet = false;
    },
    text(label) {
      return this.$t(`components.lists.timesheets.${label}`);
    },
    ...mapActions([
      'draft',
      'createTimesheet',
      'updateTimesheet',
      'initLoading',
      'fetchTimesheets',
      'fetchBranches',
      'fetchDriverProfiles',
      'fetchBusinessUnits',
      'addErrorMessage',
      'updatePage',
      'fetchUsersByName',
    ]),
    ...mapMutations([
      'setCancel',
    ]),
    fetchUsersByTerm(term) {
      this.fetchUsersByName(term);
    },
    businessUnitSelected(params) {
      this.fetchBranches({ business_unit_id: params.unit_id, by_active: true });
    },
    draftTimesheet(params) {
      this.params = params;
      this.$store.dispatch('initLoading');
      this.draft(params);
      this.dates = params;
    },
    changePage() {
      this.params.page = this.getPage;
      this.draftTimesheet(this.params);
    },
    saveDraft(draftStatus) {
      const params = {
        date_init: this.dates.date_init,
        date_end: this.dates.date_end,
        branch_id: this.branchId,
        unit_id: this.unitId,
        driver_profile_id: this.params.driver_profile_id,
        cpf: this.params.cpf,
        status: draftStatus,
      };
      this.initLoading();
      this.createTimesheet(params);
      this.fetchTimesheets({ by_status: draftStatus });
      this.goToNextTab(draftStatus);
    },
    updateOneTimesheet(draftStatus) {
      if (!this.showEditButton) {
        this.addErrorMessage(this.$t('errors.timesheet.create'));
        return;
      }

      const params = {
        id: this.getSpecificTimesheet.id,
        date_init: this.dates.date_init,
        date_end: this.dates.date_end,
        branch_id: this.branchId,
        unit_id: this.unitId,
        driver_profile_id: this.params.driver_profile_id,
        status: draftStatus,
      };

      this.initLoading();
      this.updateTimesheet(params);
      this.fetchTimesheets({ by_status: draftStatus });
      this.goToNextTab(draftStatus);
    },
    cancel() {
      this.setCancel();
      this.dates = [];
      this.unitId = null;
      this.branchId = null;
      this.cancellingTimesheet = true;
    },
    cancelEdit() {
      this.$router.push({ name: 'draftTimesheet' });
    },
    goToNextTab(status) {
      if (status === 'open') {
        this.$router.push({ name: 'draftTimesheet' });
      } else {
        this.$router.push({ name: 'closedTimesheet' });
      }
    },
    changeValuesEnterprise(value) {
      this.branchId = value.branchId;
      this.unitId = value.unitId;
    },
    timesheetWasGenerated() {
      this.showEditButton = true;
    },
  },
};
</script>

<style lang="scss">
.generate-timesheet-container {
  background: $color_background;

  .cancel-button {
    border: 1px solid $color_primary;
    border-radius: 4px;
    opacity: 1;
    text-align: center;
    color: $color_primary;
  }

  .spacing {
    margin: 8px;
  }
}
</style>

<template>
  <v-simple-table class="jornada-list">
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">
            {{ text('name') }}
          </th>
          <th class="text-center">
            {{ text('document') }}
          </th>
          <th class="text-center">
            {{ text('created_at') }}
          </th>
          <th class="text-center">
            {{ text('normal_hours') }}
          </th>
          <th class="text-center">
            {{ text('50_extra_hours') }}
          </th>
          <th class="text-center">
            {{ text('100_extra_hours') }}
          </th>
          <th class="text-center">
            {{ text('wait_time') }}
          </th>
          <th class="text-center">
            {{ text('night_increase') }}
          </th>
          <th class="text-center">
            {{ text('pay_wait_hours') }}
          </th>
          <th class="text-center">
            {{ text('total_hours') }}
          </th>
          <th class="text-center">
            {{ text('overnight_missing_total') }}
          </th>
          <th class="text-center">
            {{ text('meal_missing_total') }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="mirror in mirrors"
          :key="mirror.id"
          class="list-row"
          @click="mirror(user.id)"
        >
          <td>
            {{ mirror.user_name }}
          </td>
          <td class="text-center">
            {{ mirror.user_cpf }}
          </td>
          <td class="text-center">
            {{ formatDateISO(mirror.user_created_at) }}
          </td>
          <td class="text-center mirror">
            {{ formatHour(mirror.normal_hours) }}
          </td>
          <td class="text-center mirror">
            {{ formatHour(mirror.half_extra_hour_total) }}
          </td>
          <td class="text-center mirror">
            {{ formatHour(mirror.full_extra_hour_total) }}
          </td>
          <td class="text-center mirror">
            {{ formatHour(mirror.wait_time) }}
          </td>
          <td class="text-center mirror">
            {{ formatHour(mirror.night_increase) }}
          </td>
          <td class="text-center mirror">
            {{ formatHour(mirror.pay_wait_time) }}
          </td>

          <td class="text-center mirror">
            {{ formatHour(mirror.total_hours) }}
          </td>

          <td class="text-center mirror">
            {{ formatHour(mirror.overnight_missing_total) }}
          </td>
          <td class="text-center mirror">
            {{ formatHour(mirror.meal_missing_total) }}
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import formats from '@/mixins/formats';

export default ({
  name: 'TimesheetMirrorList',
  mixins: [formats],
  props: {
    mirrors: {
      type: Array,
      default: () => [],
    },
    show: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    text(label) {
      return this.$t(`components.lists.timesheets.${label}`);
    },
    showUser(userId) {
      this.$emit('showUserDetails', userId);
    },
  },
});
</script>
<style lang="scss" scoped>
.jornada-list {
  background-color: $color_background;
  font-family: 'Acumin-Pro-Regular';
  color: $color_list_body;

  .v-data-table__wrapper > table > thead > tr > th {
    font-weight: 400;
    color: $color_list_header;
  }

  .mirror {
    font-family: 'Acumin-Pro-Bold';
    width: 100px !important;
  }

  .list-row {
    padding: 24px 16px;
    height: 64px;

    .buttons {
      width: 120px;

      .icon {
        padding: 0 10px;
      }
    }
  }
}
</style>

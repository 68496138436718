<template>
  <v-container class="generate-header">
    <v-row
      class="content"
    >
      <v-col
        class="px-2 py-0"
        cols="12"
        sm="3"
      >
        <date-range
          :custom-date="customDate"
          @datesSelected="saveDatesAndApplyFilters"
        />
      </v-col>
      <v-col
        cols="12"
        sm="3"
        class="px-2 py-0"
      >
        <v-select
          v-model="unitId"
          :items="businessUnits"
          :placeholder="text('business_unit')"
          item-text="name"
          item-value="id"
          required
          color="accent"
          dense
          height="42"
          outlined
          @change="businessUnitSelected"
        />
      </v-col>
      <v-col
        cols="12"
        sm="3"
        class="px-2 py-0"
      >
        <v-select
          v-model="branchId"
          :items="branches"
          :placeholder="text('branch_office')"
          item-text="name"
          item-value="id"
          color="accent"
          dense
          height="42"
          outlined
        />
      </v-col>
      <v-col
        cols="12"
        sm="3"
        class="px-2 py-0"
      >
        <v-select
          v-model="selectedOption"
          :items="optionsFilters"
          :placeholder="text('options')"
          :disabled="selectedUnit"
          item-text="name"
          item-value="id"
          dense
          color="accent"
          height="42"
          outlined
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        v-if="selectedOption === 1"
        cols="9"
        sm="9"
        class="px-2 py-0"
      >
        <v-select
          v-model="profileId"
          :items="profiles"
          :placeholder="text('categories')"
          item-text="name"
          item-value="id"
          color="accent"
          dense
          height="42"
          outlined
        />
      </v-col>
      <v-col
        v-if="selectedOption === 2"
        cols="9"
        sm="9"
        class="px-2 py-0"
      >
        <v-combobox
          v-model="selectedItem"
          :search-input.sync="term"
          clearable
          :label="$t('components.header.users.driver')"
          :hint="userEmptyList"
          outlined
          dense
          color="accent"
          :items="userList"
          @click:clear="clearSelectedDriver()"
        />
      </v-col>
      <v-col
        cols="3"
        sm="3"
        class="px-2 py-0"
      >
        <v-btn
          color="accent"
          class="button pa-5"
          @click="draftTimesheet"
        >
          {{ text('draft') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import formats from '@/mixins/formats';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import DateRange from '@/components/forms/calendar/DateRange.vue';

export default {
  name: 'GenerateTimesheetHeader',
  components: {
    DateRange,
  },
  mixins: [formats],
  props: {
    params: {
      type: Object,
      default: null,
      required: false,
    },
    branches: {
      type: Array,
      default: () => [],
    },
    profiles: {
      type: Array,
      default: () => [],
    },
    businessUnits: {
      type: Array,
      default: () => [],
    },
    cancellingTimesheet: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    dates: [],
    customDate: [],
    optionsFilters: [],
    branchId: null,
    unitId: null,
    profileId: null,
    cpf: null,
    selectedOption: null,
    selectedItem: '',
    term: '',
    userList: [],
  }),
  computed: {
    ...mapGetters([
      'getDateRange',
      'getUsers',
    ]),

    selectedUnit() {
      return !this.unitId || false;
    },

    userEmptyList() {
      if (this.userList && this.userList.length === 0 && !this.selectedItem) {
        return this.$t('components.header.users.not_found');
      }
      return '';
    },
  },
  watch: {
    selectedItem(value) {
      if (value) {
        const cpfSize = (value.length) - 14;

        this.cpf = value.substr(cpfSize, value.length);
      }
    },
    term() {
      this.fetchUsers();
    },
    getUsers(value) {
      this.userList = value.map((object) => `${object.name} - ${object.cpf}`);
    },
    cancellingTimesheet(value) {
      if (value) {
        this.branchId = null;
        this.unitId = null;
        this.profileId = null;
        this.cpf = null;
        this.selectedOption = null;
        this.$emit('resetCancellingTimesheet');
      }
    },
    params(value) {
      if (value) {
        this.unitId = value.business_unit_id;
        this.branchId = value.branch_id;
        this.customDate = [
          value.date_init,
          value.date_end,
        ];
        this.businessUnitSelected();
      }
    },
    selectedOption(value) {
      if (value === 1) {
        this.cpf = null;
      } else if (value === 2) {
        this.profileId = null;
      } else {
        this.cpf = null;
        this.profileId = null;
      }
    },
  },
  mounted() {
    this.setUsers([]);
    this.optionsFilters = [
      {
        name: this.$t('components.header.timesheets.all'),
        id: 3,
      },
      {
        name: this.$t('components.header.timesheets.category'),
        id: 1,
      },
      {
        name: this.$t('components.header.timesheets.drivers'),
        id: 2,
      }];
  },
  methods: {
    ...mapActions([
      'updatePage',
    ]),
    ...mapMutations([
      'setUsers',
    ]),
    fetchUsers() {
      this.$emit('fetchUsersByTerm', {
        term: this.term || 'a',
        branch_id: this.branchId,
        unit_id: this.unitId,
      });
    },
    saveDatesAndApplyFilters(dates) {
      this.dates = dates;
    },
    businessUnitSelected() {
      this.$emit('businessUnitSelected', { unit_id: this.unitId });
    },
    text(label) {
      return this.$t(`components.header.timesheets.${label}`);
    },
    draftTimesheet() {
      if (this.unitId) {
        let selectedCPF = null;

        if (this.selectedItem) {
          selectedCPF = this.cpf;
        } else {
          selectedCPF = null;
        }
        this.updatePage(1);
        this.$emit('changeValuesEnterprise', { branchId: this.branchId, unitId: this.unitId });
        this.$emit('timesheetWasGenerated');
        this.$emit('draftTimesheet', {
          date_init: this.getDateRange[0],
          date_end: this.getDateRange[1],
          branch_id: this.branchId,
          unit_id: this.unitId,
          driver_profile_id: this.profileId,
          cpf: selectedCPF,
        });
      } else {
        this.$store.dispatch('addErrorMessage', this.$t('errors.timesheet.unitId'));
      }
    },
    clearSelectedDriver() {
      this.cpf = null;
    },
  },
};
</script>

<style lang="sccs" src="@/assets/scss/components/headers/generate_timesheet.scss" scoped />
